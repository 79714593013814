


































import { Component, Vue } from 'vue-property-decorator';
import { Card } from '@/types/cms/components';
import { Product, ProductListContent } from '@/types/neuhaus-website/cms/pages/products';
import axios, { APIResponse } from '@/plugins/axios';

/**
 * CMSPageProductList Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-component-card': () => import('@/components/cms/components/CMSComponentCard.vue'),
        'cms-component-image': () => import('@/components/cms/components/CMSComponentImage.vue')
    }
})
export default class CMSPageProductList extends Vue {
    // variable with page content
    private pageContent: ProductListContent = {
        id: -1,
        title: 'N/A',
        productCards: []
    };

    /**
     * Function will be executed on component load
     * fetches page content
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.pageContent = await this.fetchPageContent();
    }

    private getProductImageUrl(productCard: Card<Product>): string {
        return process.env.VUE_APP_API_URL + productCard.content.imageUrl;
    }

    /**
     * Fetches page content from API
     * 
     * @returns ProductListContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<ProductListContent> {
        try {
            const response = await axios.get<APIResponse<ProductListContent>>('/cms/pages/products')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.pageContent;
        }
    }

}
